export const WORDS = [
  'agape',
  'agata',
  'alelo',
  'altar',
  'aluno',
  'amago',
  'ambio',
  'ameba',
  'amigo',
  'ambar',
  'anexo',
  'ansia',
  'anexo',
  'anima',
  'animo',
  'anota',
  'apelo',
  'apito',
  'apoio',
  'araco',
  'arado',
  'areia',
  'arena',
  'aroma',
  'arroz',
  'asilo',
  'asper',
  'atimo',
  'atono',
  'atrio',
  'aurea',
  'autar',
  'autor',
  'avaro',
  'aviso',
  'azaga',
  'azeda',
  'azoto',
  'azyme',
  'banho',
  'barao',
  'bardo',
  'barro',
  'bazar',
  'beijo',
  'beira',
  'beijo',
  'bemol',
  'bento',
  'beque',
  'berco',
  'bicho',
  'bilha',
  'blitz',
  'bocal',
  'boina',
  'bombe',
  'borda',
  'botao',
  'braco',
  'bruma',
  'bucha',
  'buzio',
  'cadar',
  'canto',
  'caroa',
  'carpa',
  'cavil',
  'cedro',
  'cedro',
  'ceife',
  'celta',
  'cenho',
  'cerco',
  'cerne',
  'cesto',
  'chuva',
  'ciume',
  'coisa',
  'consu',
  'cravo',
  'cromo',
  'cruor',
  'cuido',
  'curia',
  'curto',
  'dardo',
  'decre',
  'dedal',
  'deflu',
  'dente',
  'depos',
  'digno',
  'dizel',
  'docil',
  'dogma',
  'doido',
  'dolor',
  'dorso',
  'dreno',
  'dubio',
  'dueto',
  'duque',
  'embro',
  'embut',
  'empil',
  'engen',
  'enxer',
  'epope',
  'equus',
  'ergue',
  'esboc',
  'escaf',
  'escoa',
  'escor',
  'esfer',
  'espas',
  'espor',
  'etapa',
  'exame',
  'excel',
  'exige',
  'extir',
  'extre',
  'falha',
  'farra',
  'farto',
  'fatal',
  'favor',
  'fenda',
  'festa',
  'fetic',
  'ficha',
  'fideo',
  'figue',
  'finjo',
  'fitar',
  'fixar',
  'fluir',
  'focal',
  'fofao',
  'folha',
  'forja',
  'forno',
  'forro',
  'fosso',
  'fraco',
  'frase',
  'fugaz',
  'fundo',
  'fusao',
  'gabar',
  'gague',
  'ganho',
  'garra',
  'gaspe',
  'gasto',
  'gavet',
  'genio',
  'gesso',
  'gigas',
  'glide',
  'goela',
  'gondi',
  'gorro',
  'grana',
  'grava',
  'grava',
  'greco',
  'grego',
  'grifo',
  'grilo',
  'gruta',
  'guapo',
  'guaxa',
  'gueto',
  'guizo',
  'gulha',
  'guria',
  'habil',
  'halal',
  'haste',
  'habil',
  'haver',
  'haver',
  'heroi',
  'hexis',
  'hifen',
  'honor',
  'hospu',
  'hoste',
  'humor',
  'icone',
  'ideal',
  'idolo',
  'impio',
  'inclu',
  'index',
  'indio',
  'infus',
  'inico',
  'injet',
  'integ',
  'inula',
  'irito',
  'irrir',
  'isola',
  'jacto',
  'janda',
  'janua',
  'javas',
  'jegue',
  'jogos',
  'jorro',
  'jovem',
  'jubas',
  'juizo',
  'julho',
  'junco',
  'junto',
  'jurgo',
  'labro',
  'laico',
  'largo',
  'laser',
  'latex',
  'latir',
  'lavra',
  'lavra',
  'leigo',
  'leque',
  'levar',
  'libia',
  'lider',
  'ligez',
  'limar',
  'limbo',
  'linha',
  'lipar',
  'livor',
  'lobis',
  'locao',
  'longe',
  'lorde',
  'lugar',
  'luxar',
  'madre',
  'maior',
  'manga',
  'manto',
  'marca',
  'marco',
  'marco',
  'mareu',
  'mascu',
]
